<template>
  <div id="passagewayRecord">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>停车记录管理</el-breadcrumb-item>
      <el-breadcrumb-item>出入口记录</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="table-container">
      <div class="table-filtrate">
        <ul>
          <li>
            <span class="label">输入关键字:</span>
            <el-input
              placeholder="请输入内容"
              v-model="filtrate.keyword"
              @keyup.enter.native="getList(1)"
            >
            </el-input>
          </li>
          <li>
            <span class="label">选择停车场:</span>
            <el-select
              v-model="filtrate.parkingLotId"
              placeholder="请选择"
              filterable
              clearable
              @change="parkingLotChange"
            >
              <el-option
                v-for="item in parkingLotList"
                :key="item.parkingLotId"
                :label="item.parkingLotName"
                :value="item.parkingLotId"
              >
              </el-option>
            </el-select>
          </li>
          <li>
            <span class="label">选择出入口:</span>
            <el-select
              v-model="filtrate.channelId"
              filterable
              clearable
              placeholder="请选择"
              @change="getList(1)"
            >
              <el-option
                v-for="item in filtrate.passagewayList"
                :key="item.channelId"
                :label="item.channelName"
                :value="item.channelId"
              >
              </el-option>
            </el-select>
          </li>
          <li>
            <span class="label">选择抬杆类型:</span>
            <el-select
                v-model="filtrate.liftType"
                filterable
                clearable
                placeholder="请选择"
                @change="getList(1)"
            >
              <el-option
                  v-for="item in select_liftType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              >
              </el-option>
            </el-select>
          </li>
          <li>
            <span class="label">开始时间:</span>
            <el-date-picker
              v-model="filtrate.startTime"
              type="datetime"
              placeholder="选择开始时间"
              @change="getList(1)"
            >
            </el-date-picker>
          </li>
          <li>
            <span class="label">结束时间:</span>
            <el-date-picker
              v-model="filtrate.endTime"
              type="datetime"
              placeholder="选择结束时间"
              @change="getList(1)"
            >
            </el-date-picker>
          </li>
          <li>
            <el-button type="primary" size="medium" @click="getList(1)"
              >查询</el-button
            >
          </li>
          <li>
            <el-button class="resetBtn" size="medium" @click="reset"
              >重置</el-button
            >
          </li>
        </ul>
      </div>
      <el-table
        :data="table.tableData"
        style="width: 100%"
        v-loading="table.loading"
      >
        <el-table-column prop="channelName" label="设备名称" align="center">
        </el-table-column>
        <el-table-column prop="channelType" label="设备类型" align="center">
        </el-table-column>
        <el-table-column
          prop="parkingLotName"
          label="停车场名称"
          align="center"
        >
        </el-table-column>
        <el-table-column label="车牌号1" align="center">
          <template #default="scope">
            <span :class="{ differentPlateNum: !scope.row.isSamePlateNum }">{{
              scope.row.plateNum
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="车牌号2" align="center">
          <template #default="scope">
            <span :class="{ differentPlateNum: !scope.row.isSamePlateNum }">{{
              scope.row.plateExtNum
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="liftType" label="抬杆类型" align="center">
        </el-table-column>
        <el-table-column
          prop="recordStatus"
          label="车辆停车状态"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="createAt" label="时间" align="center">
        </el-table-column>
        <el-table-column prop="address" label="图片" align="center">
          <template slot-scope="scope">
            <el-image
              style="width: 70px; height: 70px"
              :src="scope.row.imageUrl"
              fit="contain"
              :preview-src-list="[scope.row.imageUrl]"
            ></el-image>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          layout="sizes, total, prev, pager, next, jumper"
          background
          :total="table.total"
          :page-size.sync="table.pageSize"
          :current-page.sync="table.page"
          @current-change="getList"
          @size-change="getList(1)"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    "el-image-viewer": () =>
      import("element-ui/packages/image/src/image-viewer"),
  },
  data() {
    return {
      filtrate: {
        keyword: "",
        parkingLotId: "",
        channelId: null,
        liftType: null,
        passagewayList: [],
        startTime: this.$moment().subtract(14, "days"),
        endTime: new Date(),
      },
      table: {
        tableData: [],
        total: 0,
        page: 1,
        pageSize: 10,
        loading: false,
      },
    };
  },
  computed: {
    parkingLotList() {
      return this.$store.state.select.parkingLotList;
    },
  },
  created() {
    this.getPassagewayList();
    this.getList();
  },
  methods: {
    parkingLotChange() {
      this.getPassagewayList();
      this.getList(1);
    },
    reset() {
      this.filtrate.keyword = "";
      this.filtrate.parkingLotId = "";
      this.filtrate.channelId = "";
      this.filtrate.liftType = "";
      this.filtrate.startTime = "";
      this.filtrate.endTime = "";
      this.getPassagewayList();
      this.getList(1);
    },
    goToDetail(channelId) {
      this.$router.push({
        name: "passagewayDetail",
        params: { id: channelId },
      });
    },
    async getPassagewayList() {
      try {
        let res = await this.$http.post("/channel/list/all", {
          parkingLotId: this.filtrate.parkingLotId,
        });
        if (res.code === 0) {
          this.filtrate.passagewayList = res.data;
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      }
    },
    async getList(page = this.table.page) {
      this.table.page = page;
      try {
        this.table.loading = true;
        let res = await this.$http.post("/channel/list/record", {
          pageNum: page,
          pageSize: this.table.pageSize,
          keyword: this.filtrate.keyword,
          parkingLotId: this.filtrate.parkingLotId,
          channelId: this.filtrate.channelId,
          liftType: this.filtrate.liftType?this.filtrate.liftType:null,
          startTime: this.filtrate.startTime,
          endTime: this.filtrate.endTime,
        });
        if (res.code === 0) {
          for (let item of res.data.deviceChannelRecords.list) {
            item.createAt = this.$moment(item.createAt).format(
              "yyyy-MM-DD HH:mm:ss"
            );
            if (!item.plateNum || !item.plateExtNum) {
              item.isSamePlateNum = true;
            } else {
              item.isSamePlateNum = item.plateNum === item.plateExtNum;
            }
          }
          this.table.tableData = res.data.deviceChannelRecords.list;
          this.table.total = res.data.deviceChannelRecords.total;
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      } finally {
        this.table.loading = false;
      }
    },
  },
};
</script>
<style lang="scss">
#passagewayRecord {
  .el-table {
    .el-table__body-wrapper {
      .el-table__body {
        tbody {
          .el-table__row {
            .cell {
              .el-image {
                width: 53px;
                height: 38px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
